<template>
  <div class="qualityChecklistTemplateForm-wrapper">
    <form-panel
      ref="form"
      v-bind="config"
      :submitUrl="submitUrl"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail :span="24">
        <!-- <col2-block title="入库单信息"> -->
        <col2-item :span="24">
          <col2-item class="f-wh">
            <el-form-item
              label="检查表模板名称"
              prop="name"
              :rules="[{required: true, message: '请输入检查表模板名称', trigger: ['change', 'blur']}]"
            >
              <v-input v-model="form.name" :maxlength="50" placeholder="检查表模板名称" :width="width"/>
            </el-form-item>
          </col2-item>
          <col2-item class="f-wh">
            <el-form-item
              label="显示模式"
              prop="displayModel"
              :rules="[{required: true, message: '请选择显示模式', trigger: ['change', 'blur']}]"
            >
              <v-select v-model="form.displayModel" :options="displayModelOps" :width="width"/>
            </el-form-item>
          </col2-item>
        </col2-item>
        <col2-item :span="24">
          <col2-item class="f-wh">
            <el-form-item
              label="状态"
              prop="status"
              :rules="[{required: true, message: '请选择状态', trigger: 'change'}]"
            >
              <v-select v-model="form.status" :options="statusOps" :width="width"/>
            </el-form-item>
          </col2-item>
          <col2-item class="f-wh">
            <el-form-item
              v-show="form.displayModel === 1"
              label="总分数"
              prop="totalScore"
              :rules="[{required: form.displayModel === 1, message: '请输入总分数', trigger: 'blur'}]"
            >
              <v-input-number
                :min="0"
                :max="99999999"
                v-model.number="form.totalScore"
                placeholder="请输入总分数"
                :maxlength="8"
                :width="width"
              />
            </el-form-item>
          </col2-item>
        </col2-item>
      <!-- </col2-block> -->
        <!-- <col2-block title="产品信息"> -->
          <!-- 列表 -->
          <div class="btn">
            <v-button class="mar-r" text="新增" @click="toSelect"></v-button>
            <v-button type="danger" text="批量删除" class="mar-r" @click="batchDelete"></v-button>
            <div class="text" v-show="form.displayModel === 1">评分权重（%）累计占比：
              <span :class="{ error: totalWeight > 100 }">{{totalWeight}}</span>&nbsp;%
            </div>
          </div>
          <table-panel-draggable
              ref="tablePanel"
              :maxHeight="maxHeight"
              :style="{padding: '0 20px', boxSizing: 'border-box', width:'800px'}"
              :headers="tableHeaders"
              :tableData.sync="form.detailList"
              :isMultiSelect="true"
              :hasOperateColumn="false"
              :class="{ 'show-error-style': form.displayModel === 1 }"
              @handleSelectionChange="selectionChange"
              class='point-table'>
          </table-panel-draggable>
        <!-- </col2-block> -->
      </col2-detail>
    </form-panel>
    <multi-select
      ref="multiSelect"
      title="检查项列表"
      :isShow.sync="isShow"
      :searchUrl="getQualityCheckItemList2URL"
      :extraParams="extraParams"
      :headers="orgTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="orgList"
      @callback="onOrgSelect"
    >
      <template #searchSlot>
        <v-input label="检查项名称" placeholder="请输入名称" v-model="searchParams.name" />
      </template>
    </multi-select>
  </div>
</template>

<script>
import { createInputVNode, createSelectVNode } from 'common/vdom'
import {
  getQualityCheckTemplateInfoByIdURL,
  addQualityCheckTemplateURL,
  updateQualityCheckTemplateURL,
  getQualityCheckItemList2URL
  // closeOrOpenQualityCheckTemplateURL
} from './api'
import {
  statusOps,
  statusMap,
  displayModelOps,
  displayModelMap,
  isKeyItemOps,
  isKeyItemMap
} from './map'
import {
  Col2Detail,
  Col2Item,
  MultiSelect,
  TablePanel,
  TablePanelDraggable
} from 'components/bussiness'
// import { vUploader2 } from 'components/control'
// import { mobileRegular, emailAddressRegular } from 'common/regular'
// import { communityParams } from 'common/select2Params'
// import moment from 'moment'

export default {
  name: 'qualityChecklistTemplateForm',
  components: {
    Col2Detail,
    Col2Item,
    MultiSelect,
    TablePanel,
    TablePanelDraggable
  },
  data () {
    const _this = this
    return {
      onOff: false,
      width: 182,
      width2: 535,
      config: {
        submitMethod: 'POST',
        queryUrl: getQualityCheckTemplateInfoByIdURL
      },
      form: {
        name: '',
        status: undefined,
        displayModel: undefined,
        totalScore: undefined,
        // remark: '',
        // createStartTime: '',
        // createEndTime: '',
        detailList: []
        // optUserId: ''
      },
      /* 控件选项 */
      statusOps: statusOps(2),
      statusMap,
      displayModelOps: displayModelOps(2),
      displayModelMap,
      isKeyItemMap,
      /* 多选框控件 */
      getQualityCheckItemList2URL,
      isShow: false,
      searchParams: {
        name: ''
      },
      responseParams: {
        id: 'id',
        name: 'name'
      },
      responseKey: {
        id: 'itemId',
        name: 'name'
      },
      orgTableHeader: [
        {
          prop: 'name',
          width: 150,
          label: '检查项名称'
        },
        {
          prop: 'criterion',
          minWidth: 220,
          label: '检查项标准'
        },
        {
          prop: 'description',
          minWidth: 220,
          label: '检查方法及判定细则'
        }
      ],
      responseOtherKey: [
        // 'assetCategoryName',
        // 'assetName',
        // 'assetCode',
        // 'assetBarcode',
        // 'specificationModel',
        // 'brand',
        // 'unit',
        // 'price',
        // 'supplierManageName'
      ],
      orgList: [],
      /* list */
      totalWeight: undefined,
      selectIds: [],
      maxHeight: '520px',
      tableHeaders: [
        {
          default: '',
          prop: 'name',
          label: '检查项名称'
        },
        {
          default: '',
          prop: 'criterion',
          label: '检查项标准'
        },
        {
          default: '',
          prop: 'description',
          label: '检查方法及判定细则'
        },
        {
          default: undefined,
          width: 150,
          prop: 'weight',
          label: '评分权重（%）',
          formatter: (row, prop) => {
            const opts = {
              // attrs: {
              //   disabled: _this.form.displayModel !== 1,
              //   width: 120,
              //   min: 1,
              //   max: 100
              // },
              // methods: {
              //   blur (el) {
              //     el.target.className = el.target.className.split(' ').filter(v => v !== 'errorInput').join(' ')
              //     if (!row[prop] && _this.form.displayModel === 1) {
              //       el.target.className += ' errorInput'
              //     }
              //   }
              // }
              attrs: {
                disabled: _this.form.displayModel !== 1,
                width: 120,
              },
              methods: {
                input (newValue, el) {
                  // let val = isNaN(parseInt(newValue)) ? undefined : parseInt(newValue)
                  let val = newValue
                  val = val < 0 ? 0 : val
                  val = val > 100 ? 100 : val
                  // row[prop] = val
                  val = _this.clearNoNum(val)
                  _this.$set(row, prop, val)
                  el.changeModel(val)
                }
              }
            }
            return createInputVNode(this, row, prop, opts)
          }
        },
        {
          default: 0, // 1是 0否
          prop: 'isNoAccount',
          width: 150,
          label: '是否为不涉及项',
          formatter: (row, prop) => {
            const opts = {
              options: isKeyItemOps(),
              attrs: {
                width: 120
                // disabled: _this.form.displayModel !== 1
              }
            }
            return createSelectVNode(this, row, prop, opts)
          }
        }
      ]
    }
  },
  computed: {
    isEdit () {
      return this.$route.query.id !== undefined
    },
    submitUrl () {
      return this.$route.query.id ? updateQualityCheckTemplateURL : addQualityCheckTemplateURL
    },
    displayModel () {
      return this.form.displayModel
    },
    detailList () {
      return this.form.detailList
    },
    /* 全局变量 */
    userInfo () {
      return this.$store.state.userInfo
    },

    /* 表单提交and请求 */
    extraParams () {
      return {
        status: 0
      }
    }

    /* 多选框 */
  },
  watch: {
    orgList: {
      handler (val) {
        this.selectIds = []
        const list = this.form.detailList
        const arr = []
        // 1. 原有数据唯一标识集合
        const oldCodes = list.map(v => v[this.responseKey.id])
        // 2. orgList唯一标识集合
        const valIds = val.map(v => v[this.responseKey.id])
        // 3. 新增数据集合
        const addList = val.filter(v => !oldCodes.includes(v[this.responseKey.id]))
        // 4. 删除数据唯一标识集合
        const removeIds = oldCodes.filter(o => !valIds.includes(o))
        // 5. 删除数据后的原有数据集合
        const remainOldList = list.filter(a => !removeIds.includes(a[this.responseKey.id]))
        const defaultKeyValue = {
          // [this.responseKey.id]: ''
        }
        this.tableHeaders.forEach(v => {
          defaultKeyValue[v.prop] = v.default
        })
        // 新增数据
        const formatterAddList = addList.map(v => {
          let info = {}
          Object.keys(defaultKeyValue).forEach(k => {
            info[k] = v.obj[k] || defaultKeyValue[k]
          })
          return {
            ...info,
            [this.responseKey.id]: v.obj[this.responseParams.id]
          }
        })
        this.form.detailList = arr.concat(remainOldList, formatterAddList)
      },
      deep: true,
      immediate: true
    },
    displayModel (val) {
      this.$nextTick(() => {
        this.$refs.form.clearValidate('totalScore')
      })
      if (val === 1) {
        return
      }
      this.form.detailList.forEach(item => {
        this.$set(item, 'weight', undefined)
      })
    },
    detailList: {
      handler (list) {
        // Number(num.toString().match(/^\d+(?:\.\d{0,2})?/))
        this.totalWeight = list.reduce((acc, cur) => {
          return (acc*1000000000000  + Number(cur.weight || 0) * 1000000000000) / 1000000000000
        }, 0)
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    const { id } = this.$route.query
    this.onOff = false
    if (id !== undefined) {
      this.onOff = true
      this.$refs.form.getData({ id })
    }
    this.$setBreadcrumb(`${id ? '编辑' : '新增'}`)
  },
  methods: {
    update (data) { // 更新数据
      Object.keys({ ...this.form, id: '' }).forEach(key => {
        this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
      })
      let prevList = data.detailList.sort((a, b) => {
            return a.sort - b.sort
      })
      this.orgList = prevList && prevList.length > 0
        ? prevList.map(item => ({
          [this.responseKey.name]: item[this.responseKey.name],
          [this.responseKey.id]: item[this.responseKey.id],
          obj: item
        })) : []
      this.$nextTick(() => {
        this.onOff = false
      })
    },
    /* 接口类 */
    submitBefore (data) {
      if (this.totalWeight !== 100 && this.displayModel === 1) {
        this.$message(`评分权重（%）累计占比必须等于 100%`)
        return
      }
      const list = this.form.detailList
      let result = true
      let index = 0
      list.forEach((v, i) => {
        if ((!v.weight) && this.displayModel === 1) {
          result = false
          index = i + 1
        }
      })
        data.detailList.map((it,i) =>{
            it.sort = i
        })
      result || this.$message(`第${index}条数据评分权重为空！`)
      return result
    },

    /* list */
    toSelect () { // 打开上级客户多选框
      this.isShow = true
    },
    selectionChange (selection) { // table勾选
      console.log(selection)
      // this.selectedData = selection
      this.selectIds = selection.map(v => v[this.responseKey.id])
    },
    async batchDelete () { // 批量删除
      const itemIds = this.selectIds
      if (itemIds.length === 0) {
        this.$message('请选择产品信息数据')
        return
      }
      let isOk = await this.$confirm(`确认删除所选数据共${itemIds.length}条？`)
      if (isOk) {
        const list = this.orgList
        this.orgList = list.filter(v => !itemIds.includes(v[this.responseKey.id]))
      }
    },
    /* 多选控件 */
    onOrgSelect (data) { // 选中多选框内容后的回调
      console.log(data, '多选框选择内容')
    },

    /* 表单校验 */
    /** 辅助类方法 */
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },
    getEmptyValue (val, emptyVal) { // 对空值进行判断
      if (val === undefined || val === '' || val === null) {
        return emptyVal
      } else if (val === 0 || val === '0') {
        return 0
      } else if (val === false) {
        return false
      }
    },
    // 评分权重处理函数
    clearNoNum(item){
      //先把非数字的都替换掉，除了数字和.
      item = `${item}`.replace(/[^\d.]/g,"");
      //保证只有出现一个.而没有多个.
      item = item.replace(/\.{2,}/g,".");
      //只能输入两个小数
      item = item.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');
      return item
    }
  }
}
</script>

<style lang="scss" scoped>
.mar-r {
  margin-right: 8px;
}
.f-wh {
  width: 355px;
}

.qualityChecklistTemplateForm-wrapper {
    .point-table{
        margin:10px 0;
    }
  .error {
    color: #F56C6C;
    font-size: 16px;
  }
  .show-error-style {
    ::v-deep .errorInput {
      border: 1px solid #F56C6C !important;
    }
  }
  .device-panel {
    display: inline-block;
    align-items: flex-start;
    display: flex;
  }
  .check-panel {
    display: flex;
    flex-flow: wrap;
    padding: 10px;
    .checkbox-wrapper {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
  .btn {
    display: flex;
    padding: 10px 20px;
    > .text {
      flex: 1;
      display: flex;
      font-size: 14px;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
